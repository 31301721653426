<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
      <v-skeleton-loader v-show="loader" type="table" ></v-skeleton-loader>
      <!-- <v-skeleton-loader v-show="loader" max-width="500" class="mx-auto" type="card"></v-skeleton-loader> -->
      <h1 v-show="!loader" class="titulo">Relatório de Comissões de Promotores</h1>
      <v-row v-show="!loader"  justify="center">
        <!-- <v-col offset-lg="" cols="12" md="3" sm="4" lg="2" class="center-itens-datas">
          <v-autocomplete
            v-model="selectTypeEntity"
            :items="listTypeEntities"
            label="Entidades" />
        </v-col> -->
        <v-col dense cols="12" sm="2" class="pb-0">
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="birthdayMonthString"
                  :label="$gettext('Selecione o mês')"
                  @input="testeDate(birthdayMonthString)"
                  dense
                  readonly
                  v-on="on">
                  <template v-slot:append>
                    <v-icon v-on="on">
                      event
                    </v-icon>
                  </template>
                </v-text-field>
              </template>

            <v-date-picker
              v-model="form.dtInicial"
              no-title
              scrollable
              locale="pt-br"
              :type="'month'"
              @input="closeModalDatePicker(form.dtInicial)"
              :color="variables.colorPrimary">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="clearDateFilter(birthdayMonth)">Limpar</v-btn>
                <v-btn text color="primary" @click="closeModalDatePicker(form.dtInicial)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col  cols="12" sm="2" class="text-center center-itens-download">
          <!-- Botoes para download -->
          <v-btn class="bottom"  color="info" justify-self="center" @click="buscarRelatorio(true)" outlined>Buscar</v-btn>
          <div @mouseleave="visible = false" class="bottom-mobile">
            <v-btn class="bottom-2" color="info" @mouseover="visible = true"> Baixar </v-btn>
            <v-list :class="[!visible? 'none' : 'visivel']">
              <template v-for="(item, index) in items">
                <v-list-item :key="index" @click="downalod(item.value)">
                  <v-list-item-title>{{ item.title }} </v-list-item-title>
                </v-list-item>
              </template>
                </v-list>
            </div>
        </v-col>
        
        <v-col v-show="!loader" cols="12" class="conteudo-div">
          <div v-html="dados"></div>
        </v-col>
        <v-col cols="12" class="relatorio">
          <div class="relatorio tex-center">
            <div class="relatorio-pagination">
                <span  @click="incrementPage('-')"> <v-icon>keyboard_arrow_left</v-icon></span>
                <span> Página {{ page  }} de {{ pageMax }} </span>
                <span class="icon-right"  :disabled="page === pageMax" @click="incrementPage('+')">  <v-icon>keyboard_arrow_right</v-icon> </span>
            </div>
          </div>
        </v-col>
        
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import variables from '@/assets/styles/helpers/_variables.scss'
import { errorSnackbar } from '@/core/service/utils'
import { size } from 'lodash'

// mixins para com funcao padroes na geração de relatorios
import mixinRelatorio from '@/views/relatorios/mixins/relatorio'

moment.locale('pt-BR')

export default {
  name: 'RelatorioComissoes',
  mixins: [mixinRelatorio],
  data: () => ({
    selectTypeEntity: null,
    birthdayMonthString: '',
    dtFinal: '',
    birthdayMonth: '',
    menuDate: false,
    form: {
      dtInicial: moment({ hour: 0 }).format('YYYY-MM-DD'),
    },
   }),
  computed: {
    // ...mapState({ dados: state => state.relatorioGanhadoresAdmin.conteudo }),
    ...mapState('comissoesPromotores', {
      dados: 'conteudo' 
    }),
    variables: () => variables,
    sendDates () {
      moment().locale(this.$store.getters.Locale)
      if (size(this.form) !== 2){
        return {
          dtInicial: moment(this.form.dtInicial).startOf('month').add(-1, 'days').utcOffset(-3, false).format('YYYY-MM-DD'),
          dtFinal: undefined,
          userEntityPaths: size(this.user.userEntityPaths) ? this.user.userEntityPaths[0] : 1
        }
      }
      return {
        dtInicial: moment(this.form.dtInicial).startOf('month').format('YYYY-MM-DD'),
        dtFinal: undefined,
        userEntityPaths: size(this.user.userEntityPaths) ? this.user.userEntityPaths[0] : 1,
      }
    }
  },
  methods: {
    ...mapActions('comissoesPromotores', ['getHtml', 'requestRelatorio']),
    testeDate (birthdayMonthString) {
      const testeDate =  moment(birthdayMonthString, 'MMMM', true)
      if (testeDate.isValid()) {
        this.form.dtInicial = moment(birthdayMonthString, 'MMMM').format('YYYY-MM-DD')
      }
    },

    closeModalDatePicker (date) {
      this.birthdayMonthString = moment(date, 'YYYY-MM').format('MMMM')
      this.menuDate = false
    },

    InitGetRelatorio () {
      const vm = this
      let datas = {
        dtInicial: moment(this.form.dtInicial).startOf('month').format('YYYY-MM-DD'),
        dtFinal: undefined,
        userEntityPaths: size(this.user.userEntityPaths) ? this.user.userEntityPaths[0] : 1
      }
      vm.getHtml({tipo: 'html', page: vm.page, ...datas})
        .then(() => {
          setTimeout(() => {
            vm.setUrlImages()
            vm.formatStyleRelatorio()
          }, 500)
        })
        .catch(() => {
          errorSnackbar('Não possui relatório')
        })
        .finally(() => {
          this.loader = false
        })
    },
    buscarRelatorio (clear = false) {
      const vm = this
      if (clear) {
        vm.page = 1
      }
      vm.loaderPaginacao = true
      vm.getHtml({tipo: 'html', ...vm.form, page: vm.page, ...vm.sendDates}).then(() => { vm.loader = false })
        .then(() => {
          setTimeout(() => {
            vm.formatStyleRelatorio()
            vm.setUrlImages()
          }, 500)
        })
        .catch(() => {
          errorSnackbar('Não possui relatório')
        })
        .finally(() => this.loader = false)

    },
    downalod (tipo) {
      // this.requestRelatorio({tipo: tipo, ...this.form, page: this.page, ...this.sendDates }).then(async (res) => {
      this.requestRelatorio({tipo: tipo, ...this.form, ...this.sendDates }).then(async (res) => {
        // window.location = res.data.url
        window.open(res.data.url, '_blank')
      })
    },
  }
}
</script>

<style lang="scss" >
$cor: #0091ff;
.relatorio-dre-init{
  .none{
    display: none;
  }
  .conteudo-div{
    padding-top:0 ;
    > table{
      background: white !important;
    }
    @media(max-width: 1320px) {
      table{
        width: 1290px;
      }
      overflow-x: scroll;
    }
  }
  .relatorio {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    &-download {
      width: 100%;
      &-icons {
        text-align: right;
        width: 40px;
        color: $cor ;
        background:#fafafa;
        padding: 5px;
        display: inline-block;
        border-radius: 5px;
        margin-left: 5px;
        border: 1px solid $cor;
        font-size: 0.8rem;
        font-weight: 600;
        cursor: pointer;
        &:hover{
          background:$cor ;
          opacity: 0.8;
          color: white;
        }
        &:first-child{
          margin-right: 5px;
        }
      }
    }
    &-pagination{
      position: relative;
      display: flex;
      justify-content: flex-end;
      @media(max-width: 660px) {
        justify-content: center;
      }
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      span {
        display: flex;
        &:last-child {
          cursor: pointer;
        }
        &:nth-child(2) {
          justify-content: center;
          align-items: center;
        }
        &:first-child {
          cursor: pointer;
          justify-content: flex-end;
        }
      }
    }

  }
  .default-colunas {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .center-itens{
    &-download {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // @extend .default-colunas;
    }
    &-datas {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // @extend .default-colunas;
    }
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @extend .default-colunas;
    > div {
      padding: 0;
    }
  }
  .v-list {
    position: absolute;
    border: 1px solid $cor;
    background: #fafafa;
    z-index: 1;
    width: 72px;
    @media(max-width: 769px){
      right: 25px;
    }
    .v-list-item{
      min-height: 30px;
      &__title{
        font-size: 12px;
        font-weight: 600;
      }
      &:hover  {
        color: $cor !important;
      }
    }
  }
  .bottom{
    margin-right: 5px;
    box-shadow: none !important;
    &-2{
      background-color: transparent !important;
      border: 1px solid $cor;
      color: $cor !important;
      box-shadow: none !important;
    }
    &-mobile {
      @media(max-width: 769px) {
      width: 100%;
      text-align: right;
      }
    }
  }
}
</style>